"use client";

/* eslint-disable jsx-a11y/anchor-has-content */
import { Icon } from "components/Icon";
import ButtonLink from "components/Link/ButtonLink";
import ExternalDialog from "components/Link/ExternalDialog";
import type { LinkOrButtonProps } from "components/Link/Link";
import { config } from "config";
import { MouseEvent, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from "react";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { isLocalStorageSupported } from "lib/storage/is-localstorage-supported";
type ExternalProps = Omit<LinkOrButtonProps, "button"> & {
  href: string;
  button?: boolean;
  noWrap?: boolean;
  children?: ReactNode;
};

/**
 * Regular expression test
 * to check if a url should be accepted as safe
 * @see https://regex101.com/r/5dT88w/3
 */
export function isSafeExternal(href: string) {
  const protocol = "https?:\\/\\/";
  const subdomain = "\\w+\\.";
  const urls = config.general.safeExternalURLs.replace(/\./g, "\\.").split(",").join("|");
  const path = "[?#/].*";
  const re = new RegExp(`^(${protocol})?(${subdomain})*?(${urls})(${path})?$`);
  return re.test(href);
}
export default function External(props: PropsWithChildren<ExternalProps>) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => setDialogOpen(false);
  const [ignoreWarning, setIgnorewarning] = useState(false);
  useEffect(() => {
    if (!isLocalStorageSupported()) {
      return;
    }
    setIgnorewarning(localStorage.getItem("external-link-popup-disabled") === "true");
  }, [dialogOpen]);
  const handleClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    if (ignoreWarning) {
      return;
    }
    event.preventDefault();
    setDialogOpen(true);
  }, [ignoreWarning]);
  return <>
            <SafeExternalLink {...props} onClick={handleClick} data-sentry-element="SafeExternalLink" data-sentry-source-file="External.tsx" />
            {dialogOpen ? <ExternalDialog href={props.href} onClose={handleClose} /> : null}
        </>;
}
const common = {
  rel: "noreferrer noopener",
  target: "_blank"
};
export function SafeExternalLink({
  button,
  noWrap = false,
  ...props
}: ExternalProps) {
  if (button) {
    return <ButtonLink {...common} {...props as MuiButtonProps<"a">} endIcon={<StyledExternalIcon name="external-links" color="inherit" />} />;
  }
  return <MuiLink {...common} {...props as MuiLinkProps} underline="hover" noWrap={noWrap} data-sentry-element="MuiLink" data-sentry-component="SafeExternalLink" data-sentry-source-file="External.tsx">
            <WrappedText nowrap={noWrap.toString()} data-sentry-element="WrappedText" data-sentry-source-file="External.tsx">
                {props.children}&nbsp;
            </WrappedText>
            <StyledExternalIcon name="external-links" color="inherit" data-sentry-element="StyledExternalIcon" data-sentry-source-file="External.tsx" />
        </MuiLink>;
}
const WrappedText = styled("span")<{
  nowrap: string;
}>(({
  nowrap
}) => ({
  ...(nowrap === "true" && {
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 14px)",
    verticalAlign: "top",
    "&:hover": {
      textDecoration: "underline"
    }
  })
}));
const StyledExternalIcon = styled(Icon)({
  height: 14,
  width: 14
});