/* DO NOT EDIT. THIS FILE IS AUTOGENERATED. RUN `yarn build:i18n` instead. */

export const locale = {
    "alphabet": "abcdefghijklmnopqrstuvwxyzæøå",
    "article": {
        "source-article-link": "Denne artikkelen har en kildeartikkel, den finner du her:",
        "sources-title": "Kilder",
        "references-title": "Referanser",
        "authors-title": "Fagmedarbeidere",
        "former-authors": "Tidligere fagmedarbeidere",
        "international-cooperation-title": "Internasjonalt samarbeid",
        "international-cooperation-content": "NEL har et samarbeid med redaksjonene i Medibas, Lægehåndbogen og Deximed. Deres artikler er viktige kilder i NELs revisjonsarbeide"
    },
    "audio-fallback": "Nettleseren din støtter ikke audio avspilling.",
    "back": "Forrige",
    "block-content": {
        "unknown-error": "Det har oppstod en feil. Ta kontakt med oss. Informasjon: \n{{blockContent}}"
    },
    "breadcrumb": {
        "breadcrumbs": "Brødsmulesti",
        "pathnames": {
            "/calculators": "Kalkulatorer",
            "/concurrent-logins": "Samtidig innlogging",
            "/cookie-declaration": "Om informasjonskapsler",
            "/glossary": "Ordliste",
            "/logged-out": "Logget ut",
            "/search": "Søk",
            "/travel-vaccinations": "Reisevaksiner",
            "/important-updates": "Viktige oppdateringer"
        }
    },
    "choose-a-country": "Velg et land",
    "click-here": "Trykk her",
    "close-gallery": "Lukk bildekarusell",
    "close-snackbar": "Lukk",
    "close-popover": "Lukk",
    "content-menu": "Meny",
    "copy": {
        "false": "Kopiér lenken",
        "true": "Lenken kopiert"
    },
    "course": {
        "title": "Kurs"
    },
    "404": {
        "title": "404",
        "subtitle": "Siden ble ikke funnet",
        "description": "Prøv å søke etter artikkelen"
    },
    "example": "Eksempel",
    "external-link": {
        "checkbox-label": "Ikke vis denne dialogen igjen",
        "close": "Lukk",
        "title": "Ekstern lenke"
    },
    "fallback-template-name": "Artikkel",
    "favorite": {
        "favorites": "Favoritter",
        "latest": "siste {{count}}",
        "add-to-favorites": "Legg til favoritter",
        "remove-from-favorites": "Fjern fra favoritter",
        "manage-all": "Se/rediger alle",
        "none": "Ingen favoritter ennå...",
        "sort": {
            "abc": "Alfabetisk",
            "options": "Sorter favoritter",
            "time": "Dato",
            "template": "Kategorier"
        },
        "sort-by": "Sorter etter: "
    },
    "feedback": {
        "body": "Melding",
        "cancellation": "Oppsigelse av abonnement",
        "editorial-feedback": "Tips til redaksjonen",
        "email": "E-post",
        "error-sending-ticket": "Det oppstod en feil ved sending av forespørsel. Kontakt oss gjerne på support@nhi.no.",
        "forgotten-password": "Glemt brukernavn/passord",
        "general": "Teknisk feil",
        "groupId": "Hva gjelder din henvendelse?",
        "invoice": "Faktura",
        "name": "Navn",
        "order": "Bestilling",
        "price-quote": "Prisforespørsel",
        "send": "Send",
        "subject": "Emne",
        "ticket-sent": "Henvendelse sendt",
        "title": "Gi tilbakemelding / Send forespørsel"
    },
    "general-error": "Oi! Noe gikk galt.",
    "glossary": {
        "header": "Ordliste",
        "helper": "Skriv inn minst to bokstaver, eller trykk på en bokstav",
        "search-label": "Søk i ordliste",
        "title": "Ordliste (A-Å)"
    },
    "go-to-homepage": "Tilbake til forsiden",
    "header": {
        "feedback": "Gi tilbakemelding",
        "logo-alt": "Norsk Elektronisk Legehåndboka",
        "notifications": "Meldinger",
        "profile": "Åpne profil",
        "show-notifications": "Vis {{amount}} meldinger"
    },
    "home": "Hjem",
    "important-updates": {
        "important-update": "Viktig oppdatering",
        "important-updates": "Viktige oppdateringer"
    },
    "last-approved-by": "Sist revidert av",
    "last-revised": "Sist revidert",
    "last-updated": "Sist oppdatert",
    "revision-interval": "Revisjonsintervall",
    "layouts": {
        "article-skeleton": "Laster inn...",
        "error": "Feil",
        "unauthorized": "Uautorisert"
    },
    "loading-please-wait": "Laster, vennligst vent",
    "login": "Logg inn",
    "login-again": "Logg inn på nytt",
    "logout": "Logg ut",
    "megamenu": {
        "no-topics-found": "Fant ingen underkapitler",
        "close": "Lukk megameny",
        "open": "Åpne megameny"
    },
    "missing-description": "Mangler beskrivelse",
    "new-abbreviation": "NEW",
    "new-articles": {
        "new-and-updated-articles": "Nye og oppdaterte artikler",
        "copy-as-html": {
            "false": "Kopier som HTML",
            "true": "Kopiert",
            "tooltip": "Kopier hele artikkelen som HTML-kode"
        },
        "professional": "Professionelle",
        "public": "Åpne",
        "search-in-articles": "Søk i artikler",
        "from-date": "Fra dato",
        "type-of-articles": "Type artikler"
    },
    "next": "Neste",
    "no-country": "Ingen land funnet",
    "notifications": {
        "no-new-notifications": "Ingen nye meldinger",
        "notification-was-removed": "Melding fjernet",
        "remove-notification": "Fjern melding",
        "title": "Meldinger"
    },
    "on-print": {
        "copy-right": "Copyright © $year Norsk Helseinformatikk.",
        "disclaimer": "Dette er en utskrift av en artikkel fra NEL. Våre alminnelige brukerbetingelser finner du på nettsiden her: https://legehandboka.no/informasjon/forbehold",
        "print-from-product": "Utskrift fra",
        "printed-by": "skrevet ut av",
        "video-placeholder": "Nettversjonen av denne artikkelen inneholder en video. Bruk QR-koden eller nettadressen over for å se videoen i nettversjonen."
    },
    "open-section": {
        "false": "Åpne seksjon",
        "true": "Lukk seksjon"
    },
    "outdated": {
        "bg-alt": "Logoene til moderne nettlesere",
        "description": "Av sikkerhetsmessige grunner ber vi deg om å oppgradere.",
        "link": "Se alternativene her",
        "title": "Dette er en utdatert nettleser"
    },
    "paywall": {
        "toolbox-subscriber": {
            "title": "Utilgjengelig innhold",
            "text": "Har du ikke tilgang? <a href=\"mailto:salg@nhi.no\">Ta kontakt</a> for å få tilgang til kursmateriell."
        },
        "article-public-open-not-logged-in": {
            "title": "Er du helsepersonell?",
            "text": "Dette er en artikkel beregnet på pasienter. Logg inn eller kjøp abonnement for å få tilgang til utfyllende artikler for helsepersonell."
        },
        "article-public-open-no-sub": {
            "title": "Hold deg oppdatert",
            "text": "Kjøp abonnement for å få tilgang til utfyllende artikler for helsepersonell."
        },
        "article-public-not-logged-in": {
            "title": "Er du helsepersonell?",
            "text": "Dette er en artikkel beregnet på pasienter. Logg inn eller kjøp abonnement for å få tilgang til utfyllende artikler for helsepersonell."
        },
        "article-public-no-sub": {
            "title": "Hold deg oppdatert",
            "text": "Kjøp abonnement for å få tilgang til utfyllende artikler for helsepersonell."
        },
        "not-logged-in": {
            "title": "Hold deg oppdatert",
            "text": "Logg inn eller kjøp abonnement for å fortsette å lese."
        },
        "no-sub": {
            "title": "Hold deg oppdatert",
            "text": "Kjøp abonnement for å fortsette å lese."
        },
        "close": "Lukk",
        "order": "Kjøp abonnement"
    },
    "print": "Skriv ut",
    "product-name": "NEL",
    "profile": {
        "logged-in-as-organisation": "Du er logget inn med organisasjonsbruker:",
        "login-with-personal-account": "Hvis du har en personlig konto, kan du logge inn her",
        "choose-region": "Velg region",
        "close": "Lukk profil",
        "color-mode": {
            "auto": "Bytt til lys",
            "dark": "Bytt til automatisk",
            "light": "Bytt til mørk",
            "period": {
                "auto": "Mørk mellom kl. {{from}} og kl. {{to}}",
                "dark": "Mørk tema satt",
                "light": "Lys tema satt"
            }
        },
        "contact": "Kontakt oss",
        "dictionary": {
            "toggle-mark-words": "Aktiver ordforklaring fra Ordlisten",
            "description": "Når aktivert kan du markere ord i teksten og søke opp ordforklaringer fra Ordlisten"
        },
        "edit": "Rediger profil",
        "favorites": "Dine favoritter",
        "font-size": "Tekststørrelse",
        "logged-out": {
            "login-button": "Logg inn",
            "login-text": [
                "Logg inn eller bestill abonnement på NEL for å få umiddelbar tilgang.",
                "Dersom du har tilgang via ditt sykehus/institusjon, så klikk på logg inn-knappen under for å bli innlogget."
            ],
            "login-title": "Du er ikke innlogget",
            "request-licenses": "Forespør lisenser",
            "send-request": "Send forespørsel",
            "sending": "Sender...",
            "sending-failed": "Sending av epost har feilet",
            "example-email": "eksempel@eksempel.no",
            "type-email": "Skriv inn e-postadresse",
            "invalid-email": "Ugyldig e-postadresse",
            "subscribe-button": "Bestill abonnement",
            "subscribe-text": "Abonnerer du ikke på NEL?",
            "request-subject": "Forespørsmål om lisenser",
            "requester-text": "Følgende epostadresse forespør flere lisenser i NEL:"
        },
        "notifications": "Meldinger",
        "title": "Profil",
        "verified": "Verifisert bruker",
        "settings": "Innstillinger"
    },
    "published": "Publisert",
    "qr": {
        "body": "Bruk QR-koden eller nettstedsadressen for å komme til nettversjonen av artikkelen.",
        "header": "Nettversjon av artikkelen:"
    },
    "realtime-guideline": {
        "footer-tooltip": "Innholdet hentes fra helsedirektoratet.no"
    },
    "regional-content": {
        "default": "(standard)",
        "no-content-for-region": "Denne artikkel inneholder ikke regionalt innhold for følgende region: {{region}}. Vennligst velg en annen region.",
        "no-region": "Ingen region",
        "no-region-selected": "Ingen region valgt. Vennligst velg en for å kunne se regionalt innhold.",
        "set-as-default": "Lagre som standard"
    },
    "release-notes": "Versjonsmerknader",
    "reviewed": "Revidert i siste 90 dager",
    "reviewed-and-new": "Reviderte og nye artikler",
    "revised-abbreviation": "REV",
    "saved": "Lagret.",
    "search": {
        "all-hits": "Sortert søkeresultat",
        "arrow-directions": {
            "arrow-up": "PIL OPP ",
            "down": "NED ",
            "press": "Trykk ",
            "and": "og ",
            "to-navigate": "for å navigere listen"
        },
        "clear-search": "Tøm søkefeltet",
        "default-tab-label": "Alle treff",
        "error": "Søket mislyktes. Vennligst start et nytt søk. Hvis problemet oppstår, ta kontakt med oss.",
        "filter-title": "Avgrens søkeresultatet",
        "found-x-hits-for-query": "Søkeresultat for '{{query}}'{{type}}",
        "history": "Søkehistorikk",
        "improve-search": "Søkeord",
        "layout-title": "Søk",
        "new": "Ny",
        "no-hits": "Ditt søk ga dessverre ingen treff. Rediger søket og prøv på nytt.",
        "no-hits-tips": [
            "Søketips:",
            "Sjekk stavingen",
            "Prøv igjen med et synonym eller annet søkeord",
            "Prøv igjen med et mer generelt begrep"
        ],
        "no-hits-for-term": "Ingen treff på søkeord '{{query}}'",
        "revised": "Rev",
        "search": "Søk",
        "search-placeholder": "Søk etter symptomer, tilstander og mer",
        "searching": "Søker...",
        "searching-please-wait": "Søker, vennligst vent...",
        "show-more-hits": "Vis flere treff",
        "too-long": "Viser søkeresultat for '{{query}}'. Dette fordi søkfeltet er begrenset til å inneholde maksimalt 10 ord.",
        "fuzzy": "<strong>Ingen artikler samsvarte med søket ditt på '{{query}}'.</strong><br />Søkeresultatet nedenfor er basert på søk etter ord som ligner det du skrev inn."
    },
    "share-article": "Del informasjon",
    "show-more": "Vis mer",
    "show-navigation": "Vis navigasjon",
    "show-reference": "Vis referanse",
    "show-references": "Vis referanser",
    "show-tools": "Vis verktøy",
    "site-name": "NEL - Norsk Elektronisk Legehåndbok",
    "skip-to-main-content": "Hopp til hovedinnhold",
    "table": {
        "table": "Tabell",
        "open-table": "Åpne tabell"
    },
    "tools": {
        "calculators": {
            "action": "Se alle",
            "title": "Kalkulator og skjema"
        },
        "checklists": {
            "action": "",
            "title": "Sjekklister"
        },
        "courses": {
            "action": "Se alle kurs",
            "title": "Kurs"
        },
        "show-more": "Se flere",
        "show-less": "Se mindre",
        "loading": {
            "action": "",
            "content": "Vennligst vent",
            "title": "Laster inn"
        },
        "patient-information": {
            "action": "Se alle",
            "title": "Pasientinformasjon"
        },
        "regional-content": {
            "action": "Se innhold",
            "content": "Denne artikkelen inneholder beskrivelse av regionale rutiner.",
            "title": "Regionalt innhold"
        },
        "related-articles": {
            "action": "",
            "title": "Relaterte artikler"
        },
        "title": "Verktøy"
    },
    "unauthenticated-request": "Du må være logget inn",
    "unauthorized-request": "Uautorisert operasjon",
    "profession-popup": {
        "message": "Du har ikke spesifisert et yrke / spesialisering. Vennligst velg det som passer under.",
        "profession-input-label": "Profesjon",
        "speciality-input-label": "Spesialitet",
        "submit-button": "Bekreft"
    },
    "meta": {
        "og-title-suffix": ""
    },
    "user-details": {
        "confirm": "Bekreft brukerdetaljer",
        "success": "Dine opplysninger er nå oppdatert.",
        "fill-and-confirm": "Vennligst fyll ut og bekreft dine brukerdetaljer.",
        "name": "Navn",
        "email": "E-post",
        "address": "Adresse",
        "zip": "Postnummer",
        "city": "Postested",
        "specialization-1": "Spesialisering 1",
        "specialization-2": "Spesialisering 2",
        "study-program": "Studieretning",
        "profession": "Yrke",
        "profession-category": "Arbeidskategori",
        "work-location": "Arbeidssted",
        "study-location": "Studiested",
        "hpr-number": "HPR-nummer (minimum 6 siffer)",
        "remind-me-later": "Minn meg på det senere",
        "remind-me-later-text": "Ved å trykke på \"Minn meg på det senere\" utsetter du oppdateringer i 7 dager.",
        "confirm-button": "Bekreft og fortsett"
    }
} as const